import React, { useState } from 'react'

const InAppLoader = () => {

    const [activationPlan, setActivationPlan] = useState('monthly');

    const inputChoose = (selectedperiod) => {
        setActivationPlan(selectedperiod);
    }

    //handling account re-activation
    const handleResuscription = async () => {
        const reactivateAcct = await fetch('https://api.thementornextdoor.com/auth/reactivate-account', {
            method: "POST",
            credentials: "include",
            body: JSON.stringify({subPlanId: activationPlan === 'monthly' ? "prod_QwztZCrKdfpZst" : 'prod_QwzuhtMoYGU7Qe'}) ,
            headers: {
                "Content-Type": "application/json",
            },
        });

        const result = await reactivateAcct.json()

        if (!reactivateAcct.ok) {
            throw new Error(result.error || "Something went wrong");
        }

        window.location = result.url
    }

    return (
        <div id="info-popup" tabindex="-1" className="z-50 overflow-y-auto overflow-x-hidden flex items-center justify-center bg-black bg-opacity-50 fixed top-0 right-0 left-0 w-full md:inset-0 h-modal md:h-full">
            <div className="relative p-4 w-full max-w-lg h-full md:h-auto">
                <div class="relative p-4 bg-white rounded-lg shadow dark:bg-gray-800 md:p-8">
                    <div className="mb-4 text-sm font-light text-gray-500 dark:text-gray-400">
                        <h3 className="mb-3 text-2xl font-bold text-gray-900 dark:text-white">Activate Your Account</h3>
                        <p>
                            Your subscription has been canceled, which has made your account inactive. If you'd like to continue enjoying our services, you can easily resubscribe by selecting a plan below. We’d love to have you back!
                        </p>
                    </div>

                    <div class="flex items-center mb-4">
                        <input id="default-checkbox" checked={activationPlan === 'monthly'} type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 focus:ring-2" onChange={() => inputChoose('monthly')}/>
                        <label for="default-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">The Mentor Next Door - Monthly Plan</label>
                    </div>
                    <div class="flex items-center">
                        <input id="checked-checkbox" checked={activationPlan === 'annually'} type="checkbox" value="" class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" 
                        onChange={() => inputChoose('annually')}/>
                        <label for="checked-checkbox" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">The Mentor Next Door - Annual Plan</label>
                    </div>

                    <div className="justify-between items-center pt-0 mt-5 space-y-4 sm:flex sm:space-y-0">
                        <div className="items-center space-y-4 sm:space-x-4 sm:flex sm:space-y-0">
                            <button id="confirm-button" type="button" onClick={handleResuscription} className="py-2 px-4 w-full text-sm font-medium text-center text-white rounded-lg bg-[#2A62EC] sm:w-auto hover:bg-[#143176] focus:ring-4 focus:outline-none focus:ring-primary-300">Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InAppLoader