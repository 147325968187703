import React, { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const UserContext = createContext(null);

// Create the provider component
export const UserProvider = ({ children }) => {
  const [currentUserDetails, setCurrentUserDetails] = useState({});
  const [conversationsContext, setConversationContext] = useState({});
  const [loading, setLoading] = useState(false);
  const [smallLoading, setSmallLoading] = useState(false);

  const navigate = useNavigate();


  const getSingleChat = async (conversationId) => {
    setSmallLoading(true);
    try {
      // Fetch single conversation
      const response = await fetch("https://api.thementornextdoor.com/auth/get-conversation", {
        method: 'post',
        credentials: 'include',
        body: JSON.stringify({ conversationId }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Something went wrong");
      }
  
      const data = await response.json();
      console.log("all data fetched", data);
  
      // Return the fetched conversation data
      return data.history;
    } catch (error) {
      console.log("An error occurred", error);
      return [];
    } finally {
      setSmallLoading(false);
    }
  };
  

  const fetchUserData = async () => {
    setLoading(true);
    try {
      // Fetch user data based on session cookie
      const response = await fetch("https://api.thementornextdoor.com/auth/fetch-userData", {
        method: "GET",
        credentials: "include", 
      });
  
      const data = await response.json(); 
  
      if (!response.ok) {
        throw new Error(data.error || "Something went wrong");
      }
  
      setCurrentUserDetails(data);
      setConversationContext(data.conversations);
      return response;
    } catch (error) {
      console.log(error);
      return { status: error.response?.status || 500 };
    } finally {
      setLoading(false);
    }
  };

  const finalizeRegistration = async () => {

  }
  

  return (
    <UserContext.Provider
      value={{
        currentUserDetails,
        setCurrentUserDetails,
        conversationsContext,
        setConversationContext,
        setLoading,
        fetchUserData,
        loading,
        getSingleChat,
        smallLoading,
        setSmallLoading,
        finalizeRegistration,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
