import React, { useEffect, useState } from "react";
import Button from "./ui/Button";
import Input from "./forms/Input";
import Container from "./global/Container";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import PopupModal from "./PopupModal";
import CustomHook from "../CustomHook";

const Registrationform = () => {

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const userSelectedPlan = searchParams.get('ref');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [acountSuccess, setAccountSuccess] = useState(false)

  const {finalizeRegistration} = CustomHook()

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  //get user plan from local storage
  const plan = localStorage.getItem('plan');

  // useEffect(() => {
  //   try {
  //     const plan = localStorage.getItem('plan')
  //     if (!plan) {
  //       navigate('/');
  //     }
  //   } catch (error) {
  //     console.error("Invalid JSON in localStorage:", error);
  //   }
  // }, [navigate]);
  

  //SET A USESTATE TO GET USERS DETAILS
  let [confirmPassword, setconfirmPassword] = useState("");
  let [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    password: "",
    goal: "",
    subscriptionPlan: `${userSelectedPlan} plan`
  });

  useEffect(() => {
    console.log("User Details Updated:", userDetails);
  }, [userDetails]);

  useEffect(() => {
    console.log("Confirm Password Updated:", confirmPassword);
  }, [confirmPassword]);

  let confirmPasswordHandler = (e) => {
    let { value } = e.target;
    setconfirmPassword(value);
  };

  let handleChange = (e) => {
    let { name, value } = e.target;
    setUserDetails((prevValue) => ({
      ...prevValue,
      [name]: value,
    }));
  };

  const handleAccrountCreation = (e) => {
    e.preventDefault();

    //Email validator
    const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

    // Password validator
    const isValidPassword =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    //validate form inputs
    if (userDetails.name === "" || userDetails.goal === "") {
      toast.error("Fill the name or goal input");
    } else if (userSelectedPlan === '' || userSelectedPlan === null){
      toast.error('You must select a package to proceed');
      navigate('/')
    }else if (
      userDetails.email === "" ||
      !userDetails.email.match(isValidEmail)
    ) {
      toast.error("Make sure your email is valid");
    } else if (
      userDetails.password !== confirmPassword ||
      userDetails.password.match(isValidPassword)
    ) {
      if (userDetails.password !== confirmPassword) {
        toast.error("Passwords do not match.");
      } else if (!userDetails.password.match(isValidPassword)) {
        toast.error(
          "Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character."
        );
      }
    } else {
      fetch("https://api.thementornextdoor.com/auth/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userDetails),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            // Extract error message from response and throw it
            return response.json().then((err) => {
              throw new Error(err.msg || "Something went wrong");
            });
          }
        })
        .then((data) => {
          console.log(data)
          localStorage.setItem('customerId',JSON.stringify(data));
          window.location = data.url
        })
        .catch((error) => {
          // Handle errors
          toast.error(error.message);
        });
    }
  };

  const createAccountFunc = async () => {
    const Localstoragedata = JSON.parse(localStorage.getItem('customerId'));
    const session_id = Localstoragedata.sessionId;
    
    try {
      const response = await fetch(`https://api.thementornextdoor.com/auth/finalise-account-creation?session_id=${session_id}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(Localstoragedata),
      });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || "Something went wrong");
      }

      setAccountSuccess(true);

      localStorage.removeItem('customerId');
  
      console.log( 'status:', response.status, data)
      return { status: response.status, data };
  
    } catch (error) {
      // Display the error message using toast
      toast.error(error.message || "An unexpected error occurred");
      navigate('/')
      return { status: error.response?.status || 500 };
    }
  };
  
  useEffect(() => {
    if (userSelectedPlan === 'sucessPayment') {
      openModal();
      createAccountFunc().then(() => {
        console.log("Account creation successful, success modal should show.");
      }).catch((error) => {
        toast.error("Error finalizing account creation.");
      });
    } else if (userSelectedPlan === 'paymentCancelled') {
      toast.error('Payment failed');
      navigate('/');
    }
  }, [userSelectedPlan]);
  

  return (
    <Container>
      <PopupModal isOpen={isModalOpen} onClose={closeModal} success={acountSuccess} />
      <div className="flex justify-center items-center -mx-3 py-10 h-[100vh]">
        <div className="w-full flex items-center justify-center xs:w-4/5 sm:w-3/5 md:w-1/2 lg:w-2/5 xl:w-1/3 px-3">
          <section className="w-full bg-white rounded-md flex flex-col px-4 items-center justify-center">
            <div className="w-full py-3 items-start flex flex-col justify-center">
              <h1 className="text-lg capitalize font-bold font-sans text-[#334155]">
                Create Account
              </h1>
              <p className="font-semibold text-[13px] text-[#64748B]">
                Your Journey to Success Starts Here!
              </p>
            </div>
            <form className="w-full">
              <Input
                labelText="Full name"
                inputId="fullName"
                inputName="name"
                inputType="text"
                inputPlaceholder="John Doe"
                formType="registration"
                inputOnChange={handleChange}
                inputValue={userDetails.name}
              />
              <Input
                labelText="email address"
                inputId="emailAdress"
                inputName="email"
                inputType="email"
                inputPlaceholder="Example@email.com"
                formType="registration"
                inputOnChange={handleChange}
                inputValue={userDetails.email}
              />
              <Input
                labelText="password"
                inputId="password"
                inputName="password"
                inputType="password"
                formType="registration"
                inputOnChange={handleChange}
                inputValue={userDetails.password}
              />
              <Input
                labelText="confirm password"
                inputId="confirmpPssword"
                inputName="confirmPassword"
                inputType="password"
                formType="registration"
                inputOnChange={confirmPasswordHandler}
                inputValue={confirmPassword}
              />
              <Input
                labelText="What would you dream if you knew you couldn't fail?"
                inputId="goal"
                inputName="goal"
                inputType="text"
                inputPlaceholder="Your Goal..."
                formType="registration"
                inputOnChange={handleChange}
                inputValue={userDetails.goal}
              />
              <Button
                buttonType="submit"
                buttonContent="create account"
                onClick={handleAccrountCreation}
              />
              {/* <div className="mt-5 mb-2">
                <h6 className="text-slate-400 text-[11px] uppercase text-center font-bold tracking-wider">
                  SIGN UP WITH
                </h6>
              </div>
              <div className="flex w-full items-center gap-4 py-4 mb-4 justify-between">
                <button className="w-[45%] flex items-center justify-center gap-3 py-1 outline-none h-[35px] rounded-md border-[1px] border-[#475569]">
                  <FontAwesomeIcon
                    icon={faGoogle}
                    style={{
                      color: "#475569",
                      fontSize: "15px",
                    }}
                  />
                  <p>Google</p>
                </button>
                <button className="w-[45%] flex items-center justify-center gap-3 py-1 outline-none h-[35px] rounded-md border-[1px] border-[#475569]">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    style={{
                      color: "#475569",
                      fontSize: "15px",
                    }}
                  />
                  <p>Facebook</p>
                </button>
              </div> */}
            </form>
          </section>
        </div>
      </div>
    </Container>
  );
};

export default Registrationform;
