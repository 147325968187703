import React from 'react';
import { useNavigate } from 'react-router-dom';

const PopupModal = ({ isOpen, success }) => {
    const navigate = useNavigate()

    if (!isOpen) return null; // Modal is not rendered if not open

    const loginNavigate = () => {
        navigate('/userAuth/login')
    }

    return (
        <div
            className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50"
            tabIndex="-1"
        >
            <div className="relative p-4 w-full max-w-md max-h-full">
                <div className="relative bg-white rounded-lg shadow-lg dark:bg-gray-700">
                    <div className="p-4 md:p-5 text-center">

                        {/* Loading Indicator */}

                        {success ?


                            <div class="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
                                <svg aria-hidden="true" class="w-8 h-8 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
                                <span class="sr-only">Success</span>
                            </div> :

                            <svg
                                className="mx-auto mb-4 text-gray-400 w-12 h-12 dark:text-gray-200"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 20 20"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 11V6m0 8h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                            </svg>}

                        <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
                            {success? "Account Created Successfully": "Account Creation in Progress...."}
                        </h3>

                        <button
                            type="button"
                            className={`text-white ${success ? 'bg-blue-600 cursor-pointer' : 'bg-blue-200 dark:bg-blue-300 cursor-not-allowed'} font-medium rounded-lg text-sm px-5 py-2.5 text-center`}
                            disabled={!success}
                            onClick={loginNavigate}
                        >
                            Login Now
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PopupModal;
