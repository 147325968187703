import Header from '../Components/Appbar'
import Footer from '../Components/Footer'
import Breadcrumbs from '../Components/Breadcrumbs'
import Prices from '../Components/Prices'
import Faq from '../Components/Faq'

const Pricing = () => {
  return (
    <div className='w-full flex flex-col items-center justify-center'>
      <Header headerNavType='login' />
      <Breadcrumbs
        currentPageName='pricing'
        previousPage='Home'
        currentPage='pricing'
        previousPageUrl='/'
      />
      <Prices />
      <Faq />
      <Footer />
    </div>
  )
}

export default Pricing
