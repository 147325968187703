import { PencilIcon } from "@heroicons/react/24/solid";
import React, { useContext, useEffect, useState } from "react";
import Button from "../ui/Buttontwo";
import Dp from "../../Assets/Images/ImageForBigProfile.jpg";
import UserContext from "../Controller";
import CustomHook from "../../CustomHook";
import { toast } from "react-toastify";
import Label from "../forms/Label";

function Overview({ setSelectedIndex }) {
  const { fetchUserData } = CustomHook(); // Fetch the fetchUserData function from your custom hook

  let [goal, setGoal] = useState('');

  const { currentUserDetails } = useContext(UserContext); // Get currentUserDetails from UserContext

  // Fetch user data when the component mounts
  useEffect(() => {
    fetchUserData();
  }, []);

  // Ensure currentUserDetails is not null or undefined before destructuring
  const {
    goals,
  } = currentUserDetails || {};

  const handleInputGoalChange = (e) => {
    const { value } = e.target;
    setGoal(value)
  }

  const handleGoalUpdate = async (e) => {
    e.preventDefault();

    const userGoal = {
      userNewGoal: goal
    }

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userGoal),
      credentials: "include",
    }

    try {

      if (goal !== '') {
        const response = await fetch('https://api.thementornextdoor.com/auth/updateUser-goal', options)
        const result = await response.json();

        if (!response.ok) {
          throw new Error(result.msg || "Something went wrong");
        }
        toast.success(result.msg);
        setGoal('')
      } else {
        toast.error('Kindly fill the goal input');
      }
    } catch (error) {
      toast.error(error.msg);
    }
  }


  // Ensure currentUserDetails is not null or undefined before destructuring
  const {
    userPhoneNumber,
    email,
    userCountry,
    userLanguage,
    name,
  } = currentUserDetails || {};

  return (
    <div className="p-6">
      <div className="flex flex-wrap">
        <div className="md:hidden w-36 xs:w-28 sm:w-40 flex-shrink-0 mb-3">
          <div className="relative inline-flex flex-shrink-0  w-full rounded-lg overflow-hidden outline outline-2 outline-offset-2 outline-slate-300 dark:outline-slate-700">
            <img src={Dp} alt="" />
          </div>
        </div>
        <div className="w-full xs:w-[calc(100%-theme(space.36))] sm:w-[calc(100%-theme(space.48))] md:w-full xs:ms-6 sm:ms-8 md:ms-0">
          <div className="flex flex-wrap justify-between items-center mb-2 gap-x-3 gap-y-2">
            <h2 className="text-lg font-bold text-slate-700 dark:text-white">
              Personal Info
            </h2>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setSelectedIndex(2);
              }}
              size="sm"
              className="bg-blue-600 flex text-white w-fit hover:bg-blue-800"
            >
              <PencilIcon className="h-3 w-3" />
              <span className='flex gap-1'>
                Edit <span className="hidden sm:inline-block"> Profile</span>
              </span>
            </Button>
          </div>
          <div className="flex flex-wrap -mx-3">
            <div className="w-1/2 md:w-1/3 px-3">
              <div className="py-2">
                <h6 className="text-sm font-bold text-slate-600 dark:text-slate-200">
                  Name
                </h6>
                <p className="text-xs text-slate-500 dark:text-slate-400">
                  {name}
                </p>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 px-3">
              <div className="py-2">
                <h6 className="text-sm font-bold text-slate-600 dark:text-slate-200">
                  Email
                </h6>
                <p className="text-xs text-slate-500 dark:text-slate-400">
                  {email}
                </p>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 px-3">
              <div className="py-2">
                <h6 className="text-sm font-bold text-slate-600 dark:text-slate-200">
                  Phone No
                </h6>
                <p className="text-xs text-slate-500 dark:text-slate-400">
                  {userPhoneNumber}
                </p>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 px-3">
              <div className="py-2">
                <h6 className="text-sm font-bold text-slate-600 dark:text-slate-200">
                  Country
                </h6>
                <p className="text-xs text-slate-500 dark:text-slate-400">
                  {userCountry}
                </p>
              </div>
            </div>
            <div className="w-1/2 md:w-1/3 px-3">
              <div className="py-2">
                <h6 className="text-sm font-bold text-slate-600 dark:text-slate-200">
                  Language
                </h6>
                <p className="text-xs text-slate-500 dark:text-slate-400">
                  {userLanguage}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Update User's Goal */}
      <div className="mb-5 empty:mb-0 mt-11">
        <form className="w-full items-center justify-center gap-3 grid md:grid-cols-2 grid-cols-1">
          <div className="py-2 flex flex-col">
            <Label htmlFor="primaryGoal" className="mb-2">
              Current Goal
            </Label>
            <input type="text" onChange={handleInputGoalChange} id="primaryGoal" value={goals && goals.length > 0 ? goals[0].description : ''} name="primaryGoal" className="w-full h-[60px] focus:focus:outline-[#2563EB] placeholder:text-[#475569] placeholder:text-[12px] placeholder:capitalize focus:outline-1 focus:border-none border-[#E4EAF1] border-[1px] focus:ring rounded-md" disabled />
          </div>

          <div className="py-2 flex flex-col">
            <Label htmlFor="updateGoal" className="mb-2">
              New Goal
            </Label>
            <input type="text" value={goal} onChange={handleInputGoalChange} id="updateGoal" name="updateGoal" placeholder="Set your new goal..." className="w-full h-[60px] focus:focus:outline-[#2563EB] placeholder:text-[#475569] placeholder:text-[12px] placeholder:capitalize focus:outline-1 focus:border-none border-[#E4EAF1] border-[1px] focus:ring rounded-md" />
          </div>

          <Button
            onClick={handleGoalUpdate}
            className="bg-blue-600 text-white hover:bg-blue-800"
          >
            Update Goal
          </Button>
        </form>
      </div>
    </div>
  );
}

export default Overview;
