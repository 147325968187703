import React, { useContext, useEffect } from "react";
import CustomHook from "../../CustomHook";
import UserContext from "../Controller";
import Button from "../ui/Buttontwo";

function Billings() {

    const { fetchUserData } = CustomHook(); // Fetch the fetchUserData function from your custom hook

    const { currentUserDetails } = useContext(UserContext); // Get currentUserDetails from UserContext

    // Fetch user data when the component mounts
    useEffect(() => {
        fetchUserData();
    }, []);

    // making sure currentUserDetails is not null or undefined before destructuring
    const { plan, customerId } = currentUserDetails || {};

    const handlePortalLoad = async () => {
        try {
            const response = await fetch('http://localhost:8080/CustomerPortal',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({ customerId: customerId})
                }
            )

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.msg || "Something went wrong");
            }

            window.location = data.portalURL

        } catch (error) {
            console.log("An error occured", error);
        }
    }



    return (
        <>
            <div className="p-4 xs:p-6">
                <div className="flex flex-col">
                    <h6 className="text-xs font-bold text-slate-700 dark:text-white mb-3">
                        Current Package
                    </h6>
                    <div className="flex flex-wrap justify-between items-center border border-slate-200 dark:border-slate-800 px-5 py-4 rounded-md gap-3">
                        <div className="">
                            <h4 className="text-lg font-bold text-slate-600 dark:text-slate-200">
                                {plan.toLowerCase().includes('monthly') ? "£19.00" : "£190.00"}
                                <span className="text-xs font-bold text-slate-400 ms-2">
                                    Per {plan.toLowerCase().includes('monthly') ? "Month" : "Year"}
                                </span>
                            </h4>
                            <h3 className="text-xl font-bold text-slate-700 dark:text-white mb-1">
                                {plan}
                            </h3>
                        </div>
                        <ul className="flex flex-wrap gap-3">
                            <li>
                                <Button onClick={handlePortalLoad} className="bg-slate-200 dark:bg-slate-800 text-slate-600 dark:text-slate-200 hover:bg-rose-700 hover:text-white hover:dark:bg-rose-700 hover:dark:text-white">
                                    Cancel Plan
                                </Button>
                            </li>
                            <li>
                                <Button onClick={handlePortalLoad} className="bg-blue-600 text-white hover:bg-blue-800">
                                    Change Plan
                                </Button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Billings;
