import React from "react";
import { Link } from "react-router-dom";
import Container from "./global/Container";

const Appfooter = () => {
  return (
    <>
      <div className="isolate relative flex items-center justify-center w-full py-4 border-t border-slate-200 dark:border-slate-800 bg-white dark:bg-slate-950 px-7">
        {/* <Container> */}
          <div className="flex items-center justify-center w-full">
            <p className="text-[14px] text-center font-medium text-slate-600 dark:text-slate-200">
              Please note: Misha, your AI mentor, is not an all-knowing being but more like a knowledgeable friend. It may occasionally get things wrong or even exaggerate its abilities 😉. We're constantly working to improve its capabilities, so if Misha suggests something it can't do yet, rest assured we're on it.
            </p>
          </div>
        {/* </Container> */}
      </div>
    </>
  );
};

export default Appfooter;
